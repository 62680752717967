.errorPage {
    color: var(--black);
    width: 100%;
    height: 183px;
    background: var(--white);
    box-shadow: 0 -2px 12px -5px var(--grey-fourteen);
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 15px 15px 40px;
}
.errorContent h2 {
    line-height: 1.8;
    color: var(--primary-red);
    font-size: 20px;
}
.errorContent .para {
    margin: 0px 0px 10px 0px;
    line-height: 4.5;
    font-size: 14px;
}
.homeLink {
    color: var(--skyblue-one);
    font-weight: 700;
}
.homeLink:hover {
    text-decoration: underline;
    cursor: pointer;
}