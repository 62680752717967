@font-face {
  font-family: ProximaNova-Regular;
  src: url(../assets/fonts/proximanova-regular.woff) format("woff")
}
//Custom theme colours
// $theme-colors: (
//   "primary": #c50f3c,
//   "secondary": #1e1e1e,
//   "success": #E3EEDC,
//   "danger": #F4CFD8,
//   "warning":#FFF4D4,
//   "info": #F9F9F9,
//   "light": #cb2750,
//   "dark": #950c2e,
// );
//

$body-bg: #f2f2f2;
$body-color: #1e1e1e;
$grid-breakpoints: (
  xs: 0,
  sm: 375px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
);
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

:root {
  /* Grid structure */
  --col-12-grid-width: 78px;
  --col-12-grid-gutter-width: 0px 12px;

  --col-6-grid-width: 156px;
  --col-6-grid-gutter-width: 0px 24px;

  --col-4-grid-width: 234px;
  --col-4-grid-gutter-width: 0px 12px;

  --col-3-grid-width: 312px;
  --col-3-grid-gutter-width: 0px 16px;

  --col-2-grid-width: 468px;
  --col-2-grid-gutter-width: 0px 36px;

  --col-10-grid-width: 93.6px;
  --col-10-grid-gutter-width: 0px 14.4px;

  /* profile theme colors */
  --white: #ffffff;
  --red: #ff0000;
  --white-one: #f6f6f6;
  --red: #ff0000;
  --ppt-color: #c6361d;
  --doc-border-color: #7b98c1;
  --primary-red: #c50f3c;
  --primary-red-one: #c62040;
  --faint-pink: #f8d4d4;
  --primary-darkred: #950b2d;
  --primary-disabled: #c6cac6;
  --dark-white: #00000029;
  --gilead-grey: #54565b;
  --grey-one: #1e1e1e;
  --grey-three: #6b6b6b;
  --grey-four: #cccccc;
  --grey-five: #e0e0e0;
  --grey-six: #f5f5f5;
  --grey-seven: #f7f7f7;
  --grey-eight: #f3f3f3;
  --grey-nine: #262626;
  --grey-ten: #656565;
  --grey-eleven: #c4c4c4;
  --grey-twelve: #555555;
  --grey-thirteen: #b1b1b1;
  --grey-fourteen: #4f4f4f;
  --grey-fifteen: #3f3f3f;
  --grey-sixteen: #c7cac9;
  --grey-fav: #636363;
  --grey-seventeen: #d8d8d8;
  --grey-eighteen: #c2c2c2;
  --grey-nineteen: #4b4b4b;
  --grey-twenty: #2e2f32;
  --grey-twentyone: #f4f9ec;
  --grey-twentytwo: #d9d9d9;
  --grey-twentythree: #f2f2f2;
  --grey-twentyfour: #999999;
  --grey-white: #f1f1f1;
  --grey-white-one: #e4e4e4;
  --grey-white-two: #e6e6e6;
  --grey-white-three: #ebebeb;
  --grey-white-four: #f9f9f9;
  --grey-white-five: #f4f4f4;
  --grey-black: #b2b2b2;
  --grey-black-one: #aaaaaa;
  --black: #000000;
  --black-header: #0000001a;
  --black-one: #00000040;
  --bookmark-text: #060606;
  --bookmark-tick: #0cad2f;
  --yellow-light: #ffc72a;
  --yellowgreen: #b9bf15;
  --yellow-dark: #f6a800;
  --steelblue: #5988da;
  --seperate-bg: #eff8ff;
  --blue-dark: #002f87;
  --blue-kiteNetTitle: #203f90;
  --skyblue: #70b1c9;
  --skyblue-one: #5c89c3;
  --skyblue-two: #0066c5;
  --skyblue-three: #4b53bc;
  --skyblue-four: #3678ce;
  --skyblue-five: #7b98c1;
  --skyblue-six: #0064a8;
  --skyblue-seven: #02416c;
  --blue-gileadhq: #21a3ec;
  --skyblue-light: #3f85e4;
  --olive: #799c4b;
  --green: green;
  --light-green: #c7f299;
  --orange: #d3461e;
  --navy: #001f60;
  --coral: #ef907c;
  --oliveDrab: #72a951;
  --purple: #624c7b;
  --green-one: #0cad2f;
  --cyan-blue: #ced4da;
  // --dark-charcole: #333333;
  --green-two: #708a44;

  /* Typography color theme */
  --link-color: #255a89;
  --maiHeading-color: #0033a0;
  --sectionHeading-color: #4b4d4d;
  --grey-lite: #cbcbcb;
  --teams-color: #5059c9;

  /* Button color theme */
  --disable-color: #f8c9cf;
  --royalblue: #5988da;

  /* Alert themes */
  --success-alert: #e3eedc;
  --success-alert-border: #799c4b;
  --danger-alert: #f4cfd8;
  --danger-alert-border: #c50f3c;
  --warning-alert: #fff4d4;
  --warning-alert-border: #ffc72a;
  --info-alert: #f9f9f9;
  --info-alert-border: #333333;
  /*Icon colours*/
  // --yammer-color: #3678ce;
  // --gnet-news-color: #7898c1;
  // --events-color: #708a44;
  // --press-color: #aeb618;
  // --notices-color: #cc6011;
  // --stories-color: #bf9000;

  /* G.Net theme colors */
  --kitenet-color: #2e6faa;
  --gnet-news-color: #243ed0;
  --stories-gilead-color: #7f6000;
  --press-releases-color: #6d7211;
  --events-color: #517c08;
  --notices-color: #a34500;
  --social-media-color: #1e2c5d;
  --yammer-color: #0358a7;
  --emp-res-dir-color: #396c70;
  --hiring-text-color: #575b0c;
  --normal-bg-text-color: #dddfdd;
  --normal-fg-text-color: #1e1e1e;
  --normal-text-color: #6b6b6b;
  --placeholder-text-color: #6b6b6b;
  --error-text-color: #881222;
  --link-text-color: #0064a8;
  --danger-toast: #c50f3c33;
  --danger-toast-border: #c50f3c;
  --success-toast: #72a95133;
  --success-toast-border: #799c4b;
  --warning-toast: #ffc72a33;
  --warning-toast-border: #ffc72a;
  --info-toast: #33333338;
  --info-toast-border: #333333f8;
  --border-color: #d8d8d8;

  --body-font-family: ProximaNova-Regular !important;

  --p-font-size: 16px;
  --more-link-text: 14px;
  --heading-font-size: 18px;
  --copyright-text-font-size: 12px;

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-weight-black: 900;
}

.app {
  margin: auto;
  overflow: hidden;
}

*,
html {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
ol,
ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
  line-height: normal;
}
.form-select:focus {
  box-shadow: none;
}

body {
  font-family: ProximaNova-Regular !important;
}
.redirectingMSAL {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  white-space: pre-wrap;
}

.container {
  margin: 0 auto;
  max-width: none;
  padding: 0;
  position: relative;

  .subContainer {
    background: var(--white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    opacity: 1;
    padding: 0 32px;
    @media screen and (min-width: 768px) and (max-width: 1023.9px) {
      width: 768px;
    }
    @media screen and (max-width: 576px) {
      padding: 0 12px;
    }
  }

  .accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #f6f6f6;
  }

  .view-more {
    color: var(--link-color);
    font-size: var(--more-link-text);
  }

  .view-more:hover {
    color: var(--primary-red);
  }

  .topheader {
    background-color: var(--grey-shade-four);
    padding: 15px 0;
  }

  .section-title {
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    line-height: 20px;
    color: $body-color;
    margin-bottom: 16px;
    //margin-top: 32px;
  }
  .section-title:focus-visible {
    outline: none;
  }

  .title {
    font-size: var(--heading-font-size);
    font-weight: var(--font-weight-normal);
    color: var(--primary-red);
    margin: 5px 0;
  }

  .sub-title {
    font-size: var(--heading-font-size);
    font-weight: var(--font-weight-bold);
    color: $body-color;
    margin: 5px 0;
  }

  .para-title {
    font-size: var(--p-font-size);
    font-weight: var(--font-weight-bold);
    color: $body-color;
    margin: 5px 0;
  }

  .body-content {
    font-size: 16px;
    font-weight: var(--font-weight-normal);
    color: $body-color;
    margin: 5px 0;
  }

  .infotext {
    font-size: 14px;
    color: var(--grey-three);
    margin: 5px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $body-color;
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  div a:active,
  div a:hover,
  div a p:active,
  div a p:hover {
    color: var(--primary-red);
    text-decoration: none;
    //outline: 0;
  }

  button.btn {
    border-radius: 0;
    border: 0;
    font-size: var(--p-font-size);
  }

  .primarybtn {
    color: var(--white);
    font-weight: var(--font-weight-bold);
  }

  .secondarybtn {
    color: var(--grey-two);
    font-weight: var(--font-weight-normal);
  }

  // img {
  //   width: 100%;
  // }

  button:hover,
  button:focus,
  button:active,
  button:active:focus:not(:disabled):not(.disabled),
  button:focus,
  button:active,
  button:hover,
  img,
  img:hover,
  img:focus,
  img:active {
    box-shadow: none !important;
    //outline: 0;
  }

  p {
    font-size: var(--p-font-size);
  }

  .primary-red,
  .main-icon {
    color: var(--primary-red);
  }

  .gilead-grey {
    color: var(--cool-grey);
  }

  .black {
    color: var(--black);
  }

  .grey-one {
    color: var(--grey-one);
  }

  .grey-two {
    color: var(--grey-two);
  }

  .grey-three {
    color: var(--grey-three);
  }

  .grey-four {
    color: var(--grey-four);
  }

  .grey-five {
    color: var(--grey-five);
  }

  .grey-six {
    color: var(--grey-six);
  }

  .grey-seven {
    color: var(--grey-seven);
  }

  .white {
    color: var(--white);
  }

  .yellow-light {
    color: var(--yellow-light);
  }

  .yellow-dark {
    color: var(--yellow-dark);
  }

  .coral {
    color: var(--coral);
  }

  .orange {
    color: var(--orange);
  }

  .yellowgreen {
    color: var(--yellowgreen);
  }

  .olive {
    color: var(--olive);
  }

  .oliveDrab {
    color: var(--oliveDrab);
  }

  .skyblue {
    color: var(--skyblue);
  }

  .steelblue {
    color: var(--steelblue);
  }

  .royalblue {
    color: var(--royalblue);
  }

  .navy {
    color: var(--navy);
  }

  .puple {
    color: var(--purple);
  }

  .titleColor {
    color: var(--title-color);
  }

  .qnaText {
    color: var(--orange);
    font-size: var(--more-link-text);
  }

  .more_text {
    color: var(--link-color);
    font-size: var(--more-link-text);
    font-weight: var(--font-weight-normal);
  }

  .iconwrapper {
    span {
      font-size: var(--more-link-text);
      padding-right: 15px;
    }
  }
  .greyicon,
  .datetime,
  .bookmark {
    color: var(--grey-two);
  }

  /* carousel 
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 100px;
    width: 100px;
    outline: none;
    border-radius: 50%;
    border: 1px solid var(--grey-three);
    background-color: var(--white);
  }
  carousel */

  /*News and Events Left square box with icon*/
  .newsbox {
    width: 90px;
    display: block;
    text-align: center;
    padding: 16px;
    background-color: var(--white);
    border: 1px solid var(--grey-four);
  }

  /* Overlay div css */
  .overlaywrap {
    background-color: var(--white);
    box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.75);
  }

  /* Welcome Menu Profile */
  .profilepic {
    border-radius: 50%;
    background: var(--white);
    width: 40px;
    height: 40px;
    display: block;
    border: 1px solid var(--border-color);
  }

  .profilepic:hover {
    border-color: var(--primary-red);
  }
}

// fabric icons stylings

.btn-close {
  float: right;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0px !important;
  text-indent: -99999px;
  font-size: 12px;
  color: var(--grey-one);
  &:focus {
    box-shadow: none;
  }
}
.btn-close:focus-visible {
  outline: 2px solid var(--grey-three);
}

label.form-check-label {
  margin-top: 2px;
}

.closeIcon {
  color: var(--primary-red);
  position: relative;
  padding: 5px;
  cursor: pointer;
  border: 2px solid var(--primary-red);
  // border-radius: 30px;
  font-size: 10px;
  font-weight: bolder;
}

.modal {
  z-index: 1050;
  padding-left: 0px !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--primary-red) !important;
}
.react-datepicker__day--selected {
  background-color: var(--primary-red) !important;
}

.react-datepicker__day:hover {
  background-color: var(--primary-red) !important;
  color: white;
}
.react-datepicker__day--highlighted-custom {
  background-color: var(--primary-red) !important;
  color: white !important;
  border-radius: 0.3rem;
}

/* input.FormElement_dateField {
  padding: 9px 30px 7px !important;
} */
/* input[type='time']::-webkit-calendar-picker-indicator {
  //opacity: 0;
  position: absolute;
  width: 10%;
  left:35%;
} */
@media only screen and (min-width: 1280px) {
  .container {
    width: 1200px;
    padding: 20px 0 40px 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279.9px) {
  .container {
    width: 992px;
    padding: 27px 0 40px 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .newsContainer {
    width: 100% !important;
  }
  .container {
    width: 730px;
    padding: 23px 0 40px 0;
  }
}
@media only screen and (max-width: 767.9px) {
  .container {
    width: calc(100% - 48px);
    padding: 10px 0 40px 0;
  }
}

.popover .popover-arrow {
  // transform: translate3d(127px, 0px, 0px) !important;
  // position:absolute;
  display: none;
}

.shareIcon {
  font-size: 16px;
  padding-right: 12px;
  font-weight: 700;
  color: var(--grey-nineteen);
  padding-top:1px;
}
.onclickshareIcon {
  font-size: 16px;
  padding-right: 12px;
  color: var(--primary-red);
  font-weight: 700;
  padding-top:2px;
}
.onclickshareKite {
  font-size: 16px;
  padding-right: 12px;
  color: var(--kitenet-color);
  font-weight: 700;
}
.onClickLikeSolid {
  color: var(--primary-red) !important;
  font-weight: 700 !important;
}
.onbeforeLikeSolid {
  font-weight: 700 !important;
}

.ql-container.ql-snow {
  height: 250px !important;
}

// .react-datepicker-popper {
//   z-index: 9999;
// }

.react-datepicker-popper[data-placement^="bottom"] {
  z-index: 9999;
  padding-top: 0px !important;
}

.react-datepicker-popper[data-placement^="top"] {
  z-index: 9999;
}

.carousel-indicators {
  margin-bottom: -2.2rem;
  gap: 16px;
  margin-right: 16%;
}
.carousel-indicators:focus-visible {
  outline: 2px solid var(--grey-three);
}
.carousel-inner {
  overflow: hidden;
  // overflow: unset;
}
.carousel-dark .carousel-control-prev {
  width: 0;
}
.carousel-dark .carousel-control-next {
  width: 0;
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: var(--grey-three);
}
.carousel-dark .carousel-indicators [data-bs-target]:focus-visible {
  outline: 2px solid var(--grey-three);
}
.carousel-indicators .active {
  opacity: 1;
  background-color: var(--kitenet-color) !important;
}
.carousel-indicators [data-bs-target] {
  width: 32px;
  height: 8px;
}

.dropdown-toggle {
  border-radius: 0px;
}
.btn-close {
  border-radius: 0px;
}
.offcanvas {
  position: absolute !important;
  height: 100%;
}
.offcanvas-backdrop,
.offcanvas-start {
  position: absolute;
  top: 165px !important;
}
.offcanvas-start {
  width: 300px;
}
.tooltip-inner {
  width: 197px;
  background: #555555;
  border-radius: 4px;
  text-align: center;
  padding: 8px;
  position: absolute;
  z-index: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  bottom: 7px;
  left: -8px;
}

.tooltip .tooltip-arrow::before {
  border-top: 5px solid #555555 !important;
}

.css-xb97g8:hover {
  background-color: #eaebfe !important;
  color: black !important;
}

.position-absolute {
  position: fixed !important;
  z-index: 9999;
}

/* css for react-datepicker */
.react-datepicker {
  border: 1px solid var(--grey-twenty);
}
.react-datepicker__current-month {
  color: var(--grey-twenty);
  font-size: 12px;
  margin-top: 2px;
}
.react-datepicker__day:hover {
  border-radius: 0px;
}
.react-datepicker__day--keyboard-selected {
  border-radius: 0px;
}
.react-datepicker__day-name {
  color: var(--grey-twelve);
  font-size: 12px;
}
.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 0px;
}
.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 0px;
  margin-bottom: -15px;
}
.react-datepicker__navigation-icon--previous::before {
  border-color: var(--grey-twenty);
  width: 8px;
  height: 8px;
}
.react-datepicker__navigation-icon--next::before {
  border-color: var(--grey-twenty);
  width: 8px;
  height: 8px;
}
.react-datepicker__navigation:hover *::before {
  border-color: var(--grey-twenty);
}
.react-datepicker__header {
  background-color: transparent;
  border-bottom: 0px;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__day {
  border: 1px solid var(--primary-disabled);
}

.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-width: 0px calc(var(--bs-popover-arrow-width) * 0.35)
    var(--bs-popover-arrow-height);
}

.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
  width: 28px;
  height: 30px !important;
  padding-left: 0px !important;
  font-size: 18px !important;
  border: 1px solid var(--grey-seventeen);
  border-radius: 2px;
  margin-right: 2px;
}

.ql-snow .ql-color-picker .ql-picker-options {
  width: 215px !important;
  padding: 3px 4px !important;
}

.ql-snow .ql-color-picker .ql-picker-item {
  width: 30px !important;
  height: 30px !important;
}

.carousel-indicators {
  z-index: 0;
}
.dropdown-toggle::after {
  position: absolute;
  right: 16px;
  top: 18px;
}
.dropdown-toggle:hover:after {
  color: var(--primary-red);
}
.show.dropdown {
  .dropdown-toggle::after {
    color: var(--primary-red);
    transform: rotate(180deg);
  }
  // .show.dropdown{
  //   .dropdown-toggle::after{
  //     color: var(--primary-red);
  //     transform: rotate(180deg);
  //   }
}

@media screen and (max-width: 1024.99px) {
.myAppOpen {
  margin-right: 0px !important;
}
}
// added for header my apps modal on 25 oct 24
@media only screen and (width: 1024px)  {
  .myAppOpen {
    padding-right: 0px !important;
  }
}
@media only screen and (min-width: 1440px) and (max-width : 1450px) {
  .myAppOpen {
    padding-right: 0px !important;
  }
}
