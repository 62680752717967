body {
  margin: 0;
  overflow-x: hidden;
  .spinner-height {
    height: 280px;
  }
  .place-hero {
    height: 402px;
    margin-bottom: 40px;
    background-color: #80808075;
  }
  .place-heroDetail{
    height: 602px;
    margin-bottom: 40px;
    background-color: #80808075;
  }
  .place-dashboard {
    height: 100px;
    margin-bottom: 40px;
    background-color: #80808075;
  }
  .place-tabs {
    margin-bottom: 40px;
    background-color: #80808075;
  }
  .place-news {
    height: 350px;
    margin-bottom: 40px;
    background-color: #80808075;
  }
  .place-slider {
    height: 300px;
    background-color: #80808075;
    @media screen and (max-width: 767.9px) {
      margin-top: 40px;
    }
  }
  .place-sliderKite {
    height: 400px;
    margin-bottom: 40px;
    background-color: #80808075;
    @media screen and (max-width: 767.9px) {
      margin-top: 40px;
    }
  }
  .place-userContainer {
    padding: 64px 64px 80px 64px;
    background: var(--white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    height: 100%;
    @media screen and (max-width: 767.9px) {
      border: none;
      border-radius: 0px;
      padding-left: 24px;
      padding-right: 24px;
    }
    @media screen and (max-width: 1023.9px) {
      padding-top: 50px;
    }
  }
  .place-newsRoomContainer {
    background: var(--white) 0 0 no-repeat padding-box;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin: auto auto -40px;
    opacity: 1;
    overflow: hidden;
    padding-bottom: 80px !important;
    padding-left: 4px;
    padding-right: 20px;
    padding-top: 40px;
  }
  .place-userImage {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    background-color: #80808075;
  }
  .place-userText {
    height: 20px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #80808075;
  }
  .place-userTitle {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    background-color: #80808075;
  }
  .place-sliderUser {
    height: 230px;
    margin-bottom: 40px;
    background-color: #80808075;
  }

    .place-newsDetail {
      height: 300px;
      margin-bottom: 20px;
      background-color: #80808075;
    }

  .place-newsRoomTab {
    height: 40px;
    margin-bottom: 20px;
    margin-top: 16px;
    background-color: #80808075;
  }
  .place-newsRoomtitle {
    height: 30px;
    margin-bottom: 20px;
    background-color: #80808075;
  }
  .place-newsRoomFilter1 {
    height: 250px;
    margin-bottom: 30px;
    background-color: #80808075;
  }
  .place-newsRoomFilter2 {
    height: 180px;
    background-color: #80808075;
  }
  .place-newsRoomCard {
    height: 344px;
    margin-bottom: 32px;
    background-color: #80808075;
  }
  a {
    text-decoration: none;
    user-select: none;
    color: black;
  }
  .accessbilityStyles {
    color: #0064a8;
    background: #ffffff;
    height: 30px;
    left: 0px;
    padding: 8px;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 0.3s;
  }
  .accessbilityStyles:focus {
    transform: translateY(0%);
  }
}
